import $ from 'jquery'
import Util from '../../bootstrap/js/src/util'

/**
 * --------------------------------------------------------------------------
 * ONMA Online Marketing GmbH (v1.0.0): cookie-alert.js
 * --------------------------------------------------------------------------
 */

const CookieAlert = (($) => {


  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME                = 'cookie-alert'
  const VERSION             = '1.0.0'
  const DATA_KEY            = 'onma.cookie-alert'
  const EVENT_KEY           = `.${DATA_KEY}`
  const DATA_API_KEY        = '.data-api'
  const JQUERY_NO_CONFLICT  = $.fn[NAME]

  const Default = {}

  const DefaultType = {}

  const Selector = {
    COOKIE_ALERT : '[data-plugin="cookie-alert"]'
  }

  const Event = {
    LOAD_DATA_API : `load${EVENT_KEY}${DATA_API_KEY}`
  }

  /*
  const ClassName = {
  }
  */

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class CookieAlert {

    constructor(element, config) {
      this._element = element
      this._config  = this._getConfig(config)
      this.lastTime = 0
      this.days = 365

      this._acceptButton = this._element.querySelector('button')

      // const x = this._element.offsetHeight;

      if (!this._getCookie('acceptCookies')) {
        this._element.classList.add('show');
      }

      this._acceptButton.addEventListener('click', () => {
        this._setCookie('acceptCookies', true, this.days);
        this._element.classList.remove('show');
      })
    }


    // getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // public


    // private
    _getConfig(config) {
      config = $.extend({}, Default, config)
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _setCookie(cname, cvalue, exdays) {
      const dayInMilliseconds = 86400000;
      const d = new Date();
      const timeToLife = exdays * dayInMilliseconds;
      d.setTime(d.getTime() + timeToLife);
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }

    _getCookie(cname) {
      const name = `${cname}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return ''
    }

    // static

    static _jQueryInterface(config) {
      return this.each(function () {
        const $element = $(this)
        const _config = typeof config === 'object' ? config : null

        let data       = $element.data(DATA_KEY)

        if (!data) {
          data = new CookieAlert(this, _config)
          $element.data(DATA_KEY, data)
        }

        if (config === 'close') {
          data[config](this)
        }
      })
    }

  }


  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(window).on(Event.LOAD_DATA_API, () => {
    $(Selector.COOKIE_ALERT).each(function () {
      const $cookieAlert = $(this)
      CookieAlert._jQueryInterface.call($cookieAlert, $cookieAlert.data())
    })
  })


  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME]             = CookieAlert._jQueryInterface
  $.fn[NAME].Constructor = CookieAlert
  $.fn[NAME].noConflict  = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return CookieAlert._jQueryInterface
  }

  return CookieAlert

})($)

export default CookieAlert
